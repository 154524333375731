import React, { useState } from 'react'; // Import React and useState together
import { Button, Card, Col, Container, Row, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import SimpleBar from 'simplebar-react';


import DataOnImg from '../../../assets/dist/img/connectors/fivetran.svg'; // Example SVG for the modal
//Images
import symbolAvatar1 from '../../../assets/dist/img/connectors/quickbooks.svg';
import symbolAvatar2 from '../../../assets/dist/img/connectors/hubspot.svg';
import symbolAvatar3 from '../../../assets/dist/img/connectors/sap.svg';
import symbolAvatar4 from '../../../assets/dist/img/connectors/netsuite.svg';
import symbolAvatar5 from '../../../assets/dist/img/connectors/salesforce.svg';
import symbolAvatar6 from '../../../assets/dist/img/connectors/bill.svg';
import symbolAvatar7 from '../../../assets/dist/img/connectors/stripe.svg';
import symbolAvatar8 from '../../../assets/dist/img/connectors/blackline.svg';
import symbolAvatar9 from '../../../assets/dist/img/connectors/invoiced.svg';
import symbolAvatar10 from '../../../assets/dist/img/connectors/invoicely.svg';
import symbolAvatar11 from '../../../assets/dist/img/connectors/highradius.svg';
import symbolAvatar12 from '../../../assets/dist/img/connectors/paypal.svg';

const Body = () => {
    const [showModal, setShowModal] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const history = useHistory();

    const handleConnectSalesforce = () => {
        setShowModal(true);
    };

    const handleSvgClick = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false); // Hide notification after a few seconds
            history.push('/auth/data-on'); // Update with your desired path
        }, 2000); // 2 seconds delay
    };

    return (
        <div className="integrations-body">
            <SimpleBar className="nicescroll-bar">
                <Container className="mt-md-7 mt-3">
                    <h3>All your data in one place.</h3>
                    <p>Pull in data with just a few clicks. Streamline your operations, gain comprehensive insights, and tailor your workflow.<br></br> Don’t see the connector your looking for? Build your own Custom Connector or Contact us.</p><br></br>
                    <Row>
                        <Col xxl={3} xl={4} md={6}>
                            <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar1} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block">Connect Quickbooks</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col xxl={3} xl={4} md={6}>
                        <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar2} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0">Connect Hubspot</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3} xl={4} md={6}>
                        <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar3} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0">Connect SAP</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3} xl={4} md={6}>
                        <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar4} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0">Connect NetSuite</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3} xl={4} md={6}>
                        <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar5} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0" onClick={handleConnectSalesforce}>Connect Salesforce</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3} xl={4} md={6}>
                        <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar6} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0">Connect Bill</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3} xl={4} md={6}>
                        <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar7} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0">Connect Stripe</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3} xl={4} md={6}>
                            <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar8} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0">Connect Blackline</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3} xl={4} md={6}>
                        <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar9} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0">Connect Invoiced</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3} xl={4} md={6}>
                        <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar10} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0">Connect Invoicely</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3} xl={4} md={6}>
                        <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar11} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0">Connect HighRadius</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xxl={3} xl={4} md={6}>
                        <Card className="card-border card-int mb-4"> {/* Adjust the height as needed */}
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="mb-3 text-center">
                                        <img src={symbolAvatar12} alt="Quickbooks" style={{ width: '200px' }}/>
                                    </div>
                                    <br></br><br></br>
                                    <Button variant="primary" className="btn-block flex-grow-0 flex-shrink-0 flex-basis-0">Connect PayPal</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </SimpleBar>

            {/* Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                <Modal.Body className="text-center" style={{ position: 'relative' }}> {/* Add relative positioning */}
                    <img 
                        src={DataOnImg} 
                        alt="DataOn" 
                        style={{ 
                            cursor: 'pointer',
                            maxWidth: '100%',
                            maxHeight: '70vh',
                            objectFit: 'contain'
                        }} 
                        onClick={handleSvgClick}
                    />
                    {showNotification && (
                        <div style={{
                            position: 'absolute', // Absolute positioning
                            top: '50%',           // Center vertically
                            left: '50%',          // Center horizontally
                            transform: 'translate(-50%, -50%)', // Adjust for centering
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            zIndex: 2 // Ensure it's above the image
                        }}>
                            Redirecting...
                        </div>
                    )}
                </Modal.Body>
            </Modal>


        </div>

    )
}

export default Body
