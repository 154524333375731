import { PhoneCall } from 'react-feather';
//Images
import avatar1 from '../../../assets/dist/img/avatar1.jpg';
import avatar2 from '../../../assets/dist/img/avatar2.jpg';
import avatar3 from '../../../assets/dist/img/avatar3.jpg';
import avatar7 from '../../../assets/dist/img/avatar7.jpg';
import avatar8 from '../../../assets/dist/img/avatar8.jpg';
import avatar9 from '../../../assets/dist/img/avatar9.jpg';
import avatar13 from '../../../assets/dist/img/avatar13.jpg';
import avatar15 from '../../../assets/dist/img/avatar15.jpg';

export const contacts = [
    {
        id: 1,
        name: "AI Agent 01",
        avatar: avatar2,
        time: "Yesterday",
        lastChat: "Description of agent.",
        status: "active",
        unread: 15,
    },
    {
        id: 2,
        name: "AI Agent 02",
        avatar: avatar8,
        time: "10:25PM",
        lastChat: "Description of agent.",
        status: "active",
        unread: 0,
    },
    {
        id: 3,
        name: "AI Agent 03",
        avatar: avatar13,
        time: "5 min",
        lastChat: "Description of agent.",
        status: "",
        unread: 2,
    },
    {
        id: 4,
        name: "AI Agent 04",
        initAvatar: { title: "W", variant: "soft-danger" },
        time: "3:15 PM",
        lastChat: "Description of agent.",
        status: "",
        unread: 0,
    },
    {
        id: 5,
        name: "AI Agent 05",
        avatar: avatar1,
        time: "Yesterday",
        lastChat: "Description of agent.",
        status: "",
        unread: 0,
    },
    {
        id: 6,
        name: "AI Agent 06",
        avatar: avatar15,
        time: "5:23 AM",
        lastChat: "Description of agent.",
        status: "active",
        unread: 0,
    },
    {
        id: 7,
        name: "AI Agent 07",
        initAvatar: { title: "H", variant: "primary" },
        time: "24 Jan",
        lastChat: "Description of agent.",
        status: "",
        unread: 0,
    },
    {
        id: 8,
        name: "AI Agent 08",
        avatar: avatar3,
        time: "4:05 AM",
        lastChat: "Description of agent.",
        status: "active",
        unread: 37,
    },
    {
        id: 9,
        name: "AI Agent 09",
        avatar: avatar7,
        time: "7:40 PM",
        lastChat: "Description of agent.",
        status: "active",
        unread: 0,
    },
    {
        id: 10,
        name: "AI Agent 10",
        avatar: avatar9,
        time: "Yesterday",
        lastChat: "Description of agent.",
        status: "",
        unread: 0,
    },
]