import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Image
import DataOnImg from '../../../assets/dist/img/connectors/dataon.svg';

const DataOn = () => {
    const history = useHistory();
    const [showNotification, setShowNotification] = useState(false);

    // Handler for image click
    const handleImageClick = () => {
        setShowNotification(true);

        // Redirect after a short delay
        setTimeout(() => {
            history.push('/apps/contacts/contact-list'); // replace '/new-path' with your desired path
            setShowNotification(false); // Hide notification after redirect
        }, 2000); // 2 seconds delay
    }

    return (
        <>
            <div style={{
                height: '100vh',
                width: '100vw',
                backgroundSize: 'cover', 
                backgroundPosition: 'center',
                position: 'relative' // For positioning the notification
            }}>
                <img 
                    src={DataOnImg} 
                    style={{
                        height: '100%', 
                        width: '100%', 
                        objectFit: 'cover'
                    }} 
                    alt="Salesforce"
                    onClick={handleImageClick} 
                />
                {showNotification && (
                    <div style={{
                        position: 'absolute',
                        top: '50%', 
                        left: '50%', 
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'rgba(0,0,0,0.7)',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '10px',
                        zIndex: 1000
                    }}>
                        Authorizing...
                    </div>
                )}
            </div>
        </>
    );
}

export default DataOn;
