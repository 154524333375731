import React, { useState, useEffect } from 'react';
import HkAvatarUploader from '../../../components/@hk-avatar-uploader/@hk-avatar-uploader';
import HkTooltip from '../../../components/@hk-tooltip/HkTooltip';
import { Badge, Button, Card, Col, Dropdown, Form, Nav, Row, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBehance, faDropbox, faGithub, faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import { Activity, CheckSquare, Clock, Edit2, Edit3, Heart, Mail, Phone, Plus, Shield, Trash, Upload, Video, Zap } from 'react-feather';
//Internal Components
import ProfileInfo from './ProfileInfo';
import MoreInfo from './MoreInfo';
import AddTags from './AddTags';
import AddBio from './AddBio';
import HkBadge from '../../../components/@hk-badge/@hk-badge';
import HkDataTable from '../../../components/@hk-data-table';
import { columns, data } from '../../Invoices/InvoiceList/TableData';

import AudioCallModal from '../../Chat/AudioCallModal';
import { useWindowWidth } from '@react-hook/window-size';
import classNames from 'classnames';
import VideoCallModal from '../../Chat/VideoCallModal';



//Image
import avatar2 from '../../../assets/dist/img/avatar2.jpg';



const EditContactBody  = ({ topNavCollapsed, toggleTopNav, infoState, infoToggle, invitePeople, StartConversation, startChating, avatar, userName }) => {
    const [profileInfo, setProfileInfo] = useState(false);
    const [moreInfo, setMoreInfo] = useState(false);
    const [addTags, setAddTags] = useState(false);
    const [addBio, setAddBio] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [audioCall, setAudioCall] = useState(false);
    const [videoCall, setVideoCall] = useState(false);

      // Add state for active tab
    const [activeTab, setActiveTab] = useState('tab_summery');

    const handleSelectTab = (key) => {
        setActiveTab(key);
    };

    return (
        <>
            <div className="contact-body contact-detail-body">
                <SimpleBar className="nicescroll-bar">
                    


            <Card className="card-border mb-0 h-100">
            
            <Card.Body>
                <div className="flex-grow-1 ms-lg-3">
                    <Row>
                        <Col xxl={3} sm={6} className="mb-3">
                            <span className="d-block fw-medium fs-7">Total Due</span>
                            <div className="d-flex align-items-center">
                                <span className="d-block fs-4 fw-medium text-dark mb-0">$449,837.23</span>
                                <HkBadge bg="success" size="sm" soft className="ms-1" >
                                    <i className="bi bi-arrow-up" /> 7.5%
                                </HkBadge>
                            </div>
                        </Col>
                        <Col xxl={3} sm={6} className="mb-3">
                            <span className="d-block fw-medium fs-7">Outstanding Invoices</span>
                            <div className="d-flex align-items-center">
                                <span className="d-block fs-4 fw-medium text-dark mb-0">32</span>
                                <HkBadge bg="danger" size="sm" soft className="ms-1" >
                                    <i className="bi bi-arrow-up" /> 27%
                                </HkBadge>
                            </div>
                        </Col>
                        <Col xxl={3} sm={6} className="mb-3">
                            <span className="d-block fw-medium fs-7">Active Disputes</span>
                            <div className="d-flex align-items-center">
                                <span className="d-block fs-4 fw-medium text-dark mb-0">4</span>
                                <HkBadge bg="success" size="sm" soft className="ms-1" >
                                    <i className="bi bi-arrow-up" /> 32%
                                </HkBadge>
                            </div>
                        </Col>
                        <Col xxl={3} sm={6}>
                            <span className="d-block fw-medium fs-7">Health</span>
                            <div className="d-flex align-items-center">
                                <span className="d-block fs-4 fw-medium text-dark mb-0">80%</span>
                                <HkBadge bg="success" size="sm" soft className="ms-1" >
                                    <i className="bi bi-arrow-down" /> -0.88%
                                </HkBadge>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Card.Body>
            </Card>

                    <div className="d-flex flex-xxl-nowrap flex-wrap">
                        <div className="contact-info w-xxl-25 w-100">
                            <Dropdown className="action-btn">
                                <Dropdown.Toggle variant="light">Action</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>Action</Dropdown.Item>
                                    <Dropdown.Item>Another action</Dropdown.Item>
                                    <Dropdown.Item>Something else here</Dropdown.Item>
                                    <Dropdown.Divider as="div" />
                                    <Dropdown.Item>Separated link</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="text-center mt-5">
                                <div className="mx-auto" style={{ width: 118 }} >
                                    <HkAvatarUploader
                                        defaultImg={avatar2}
                                    />
                                </div>
                                <div className="cp-name text-truncate mt-3">Mendaline Shane</div>
                                <p>Head of Procurement</p>
                                <Rating initialValue={3} readonly size="20" />
                                <ul className="hk-list hk-list-sm justify-content-center mt-2">
                                    <li>
                                        <Button variant="soft-primary" className="btn-icon btn-rounded">
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Mail />
                                                </span>
                                            </span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button variant="soft-success" className="btn-icon btn-rounded" onClick={() => setAudioCall(!audioCall)} >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Phone />
                                                </span>
                                            </span>
                                        </Button>
                                    </li>
                                    <li>
                                        <Button variant="soft-danger" className="btn-icon btn-rounded" onClick={() => setVideoCall(!videoCall)} >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Video />
                                                </span>
                                            </span>
                                        </Button>
                                    </li>
                                </ul>
                            </div>
                            <Card>
                                <Card.Header>
                                    <Link to="#">Contact Info</Link>
                                    <Button variant="light" size="xs" className="btn-icon btn-rounded" onClick={() => setProfileInfo(!profileInfo)} >
                                        <HkTooltip placement="top" title="Edit" >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Edit2 />
                                                </span>
                                            </span>
                                        </HkTooltip>
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="cp-info">
                                        <li>
                                            <span>First name</span>
                                            <span>Mendaline</span>
                                        </li>
                                        <li>
                                            <span>Last name</span>
                                            <span>Shane</span>
                                        </li>
                                        <li>
                                            <span>Email</span>
                                            <span>medaline@acmecorp.com</span>
                                        </li>
                                        <li>
                                            <span>Phone</span>
                                            <span>+1-715-4532-1234</span>
                                        </li>
                                        <li>
                                            <span>Location</span>
                                            <span>New York</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                            <div className="separator-full" />
                            <Card>
                                <Card.Header>
                                    <Link to="#">Organization Info</Link>
                                    <Button variant="light" size="xs" className="btn-icon btn-rounded" onClick={() => setMoreInfo(!moreInfo)}>
                                        <HkTooltip placement="top" title="Edit" >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Edit2 />
                                                </span>
                                            </span>
                                        </HkTooltip>
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="cp-info">
                                        <li>
                                            <span>Name</span>
                                            <span>Acme Corporation</span>
                                        </li>
                                        <li>
                                            <span>Industry</span>
                                            <span>Supply Chain</span>
                                        </li>
                                        <li>
                                            <span>Address</span>
                                            <span>128 St Bank Lane</span>
                                        </li>
                                        <li>
                                            <span>City</span>
                                            <span>Lewes</span>
                                        </li>
                                        <li>
                                            <span>State</span>
                                            <span>Delaware</span>
                                        </li>
                                        <li>
                                            <span>ZipCode</span>
                                            <span>198332</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                            <div className="separator-full" />
                            <Card>
                                <Card.Header>
                                    <Link to="#">Tags</Link>
                                    <Button variant="light" size="xs" className="btn-icon btn-rounded" onClick={() => setAddTags(!addTags)} >
                                        <HkTooltip placement="top" title="Add Tags" >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Plus />
                                                </span>
                                            </span>
                                        </HkTooltip>
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <Badge bg='soft-violet' className="badge-soft-violet me-1">Collaboration</Badge>
                                    <Badge bg='soft-danger' className="badge-soft-danger me-1">Supply Chain</Badge>
                                </Card.Body>
                            </Card>
                            <div className="separator-full" />
                            <Card>
                                <Card.Header>
                                    <Link to="#">Social Profile</Link>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="hk-list hk-list-sm">
                                        <li>
                                            <Button variant="primary" className="btn-icon btn-rounded">
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faBehance} />
                                                </span>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button variant="warning" className="btn-icon btn-rounded">
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faGoogleDrive} />
                                                </span>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button variant="info" className="btn-icon btn-rounded">
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faDropbox} />
                                                </span>
                                            </Button>
                                        </li>
                                        <li>
                                            <Button variant="dark" className="btn-icon btn-rounded">
                                                <span className="icon">
                                                    <FontAwesomeIcon icon={faGithub} />
                                                </span>
                                            </Button>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                            <div className="separator-full" />
                            <Card>
                                <Card.Header>
                                    <Link to="#">Partnership Notes</Link>
                                    <Button variant="light" size="xs" className="btn-icon btn-rounded" onClick={() => setAddBio(!addBio)} >
                                        <HkTooltip placement="top" title="Edit" >
                                            <span className="icon">
                                                <span className="feather-icon">
                                                    <Edit2 />
                                                </span>
                                            </span>
                                        </HkTooltip>
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <p>We've been working with Acme since they moved to Delaware. Great Partners.</p>
                                </Card.Body>
                            </Card>
                            <div className="separator-full" />
                            <Card>
                                <Card.Header>
                                    <Link to="#">Settings</Link>
                                </Card.Header>
                                <Card.Body>
                                    <ul className="cp-action">
                                        <li>
                                            <Link to="#">
                                                <span className="cp-icon-wrap">
                                                    <span className="feather-icon">
                                                        <Upload />
                                                    </span>
                                                </span>
                                                Share Contact
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <span className="cp-icon-wrap">
                                                    <span className="feather-icon">
                                                        <Heart />
                                                    </span>
                                                </span>
                                                Add to Favourites
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" className="link-danger">
                                                <span className="cp-icon-wrap">
                                                    <span className="feather-icon">
                                                        <Trash />
                                                    </span>
                                                </span>
                                                Delete Contact
                                            </Link>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="contact-more-info">
                            <Tab.Container activeKey={activeTab} onSelect={handleSelectTab}>
                                <Nav variant='tabs' className="nav-line nav-icon nav-light">
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab_summery">
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Zap />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Activity</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="tab_invoices">
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Activity />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Invoices</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link>
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Edit3 />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Disputes</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    
                                    <Nav.Item>
                                        <Nav.Link>
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Mail />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Emails</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => setAudioCall(!audioCall)} >
                                        <Nav.Link>
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Phone />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Call</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link>
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <CheckSquare />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Tasks</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link>
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Clock />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Schedule</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link>
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Edit3 />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Notes</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link>
                                            <span className="nav-icon-wrap">
                                                <span className="feather-icon">
                                                    <Shield />
                                                </span>
                                            </span>
                                            <span className="nav-link-text">Sales</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className="mt-7">
                                    <Tab.Pane eventKey="tab_summery">
                                    <div className="pipeline-status-wrap mt-7" style={{ width: '100%' }}>
                                        <ul className="pipeline-stutus" style={{ width: '100%' }}>
                                            <li className="completed"><span>Overdue</span></li>
                                            <li className="completed"><span>First Notice</span></li>
                                            <li className="active"><span>Follow up</span></li>
                                            <li><span>Promise to Pay</span></li>
                                            <li><span>Paid</span></li>
                                        </ul>
                                        <div className="clearfix" />
                                        <br></br>
                                        
                                    </div>
                                    <br></br>
                                    <Row>
                                        <Col xxl={3} sm={6} className="mb-3">
                                            <span className="d-block fw-medium fs-7">Inv #33994</span>
                                            <div className="d-flex align-items-center">
                                                <span className="d-block fs-4 fw-medium text-dark mb-0">$42,327.23</span>
                                                <HkBadge bg="danger" size="sm" soft className="ms-1" >
                                                    <i className="bi bi-arrow-up" /> 35 days past due
                                                </HkBadge>
                                            </div>
                                        </Col>
                                    </Row>  
                                        <Form>
                                            <Row>
                                                <Col md={12} as={Form.Group} className="mb-3" >
                                                    <div className="form-label-group">
                                                        <Form.Label>AI Recommended next action</Form.Label>
                                                        <small className="text-muted">1200</small>
                                                    </div>
                                                    <Form.Control 
                                                        as="textarea" 
                                                        rows={8} 
                                                        placeholder={`Hi Mack,

Invoice #33994 is now 35 days past due. Please remit.
If payment is not received by Tuesday, January 10, 2024,
we will suspend your account.
                                                        
Regards,
Mary`}
                                                    />

                                                </Col>
                                            </Row>
                                            <Button variant="primary" className="mt-2">Send</Button>
                                        </Form>
                                    
                                        <div className="activity-wrap mt-7">
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className="title-lg mb-0">Activity</div>
                                    <Form.Select className="mw-150p">
                                        <option value={0}>All Activity</option>
                                        <option value={1}>One</option>
                                        <option value={2}>Two</option>
                                        <option value={3}>Three</option>
                                    </Form.Select>
                                </div>
                                <div className="title-sm text-primary mb-3">Dec 22</div>
                                <ul className="activity-thread">
                                    <li>
                                        <div className="media">
                                            <div className="media-head">
                                                <div className="avatar avatar-icon avatar-sm avatar-primary avatar-rounded">
                                                    <span className="initial-wrap"><span className="feather-icon">
                                                        <Mail />
                                                    </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div>
                                                    <div className="activity-text">You sent <span className="text-dark text-capitalize">1 Email</span> regarding invoice #23129 to the contact.</div>
                                                    <div className="activity-time">10.00 pm</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="media">
                                            <div className="media-head">
                                                <div className="avatar avatar-sm avatar-soft-danger avatar-rounded">
                                                    <span className="initial-wrap">M</span>
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div>
                                                    <div className="activity-text"><span className="text-dark text-capitalize">Mendaline Shane</span> made a promise to pay</div>
                                                    <div className="activity-time">10.00 pm</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="media">
                                            <div className="media-head">
                                                <div className="avatar  avatar-icon avatar-sm avatar-info avatar-rounded">
                                                    <span className="initial-wrap"><span className="feather-icon">
                                                        <Shield />
                                                    </span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div>
                                                    <div className="activity-text">Your invoice #23922 <span className="text-dark">$32,208.15</span> was paid through PayU Money online on <span className="text-dark">Monday, 21 Dec,</span> at <span className="text-dark">15:30.</span></div>
                                                    <div className="activity-time">10.00 pm</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div className="title-sm text-primary mt-5 mb-3">Nov 25</div>
                                <ul className="activity-thread">
                                    <li>
                                        <div className="media">
                                            <div className="media-head">
                                                <div className="avatar avatar-sm avatar-soft-danger avatar-rounded">
                                                    <span className="initial-wrap">M</span>
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div>
                                                    <div className="activity-text"><span className="text-dark">Mendaline Shane</span> responded to your email. </div>
                                                    <div className="activity-time">10.00 pm</div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab_invoices">
                                        <Row className="mb-3">
                                                <Col xs={7} mb={3}>
                                                    <div className="invoice-toolbar-left">
                                                        <Form.Select size="sm" className="d-flex align-items-center w-130p">
                                                            <option value={1}>Export to CSV</option>
                                                            <option value={2}>Export to PDF</option>
                                                            <option value={3}>Send Message</option>
                                                            <option value={4}>Delegate Access</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col xs={5} mb={3}>
                                                    <div className="d-flex justify-content-end">
                                                        <div className="dataTables_filter" style={{ margin: 7 }} >
                                                            <label>
                                                                <Form.Control
                                                                    size='sm'
                                                                    type='search'
                                                                    placeholder='Search'
                                                                    value={searchTerm}
                                                                    onChange={e => setSearchTerm(e.target.value)}
                                                                />
                                                            </label>
                                                        </div>
                                                        <div className="dataTables_paginate paging_simple_numbers" id="datable_1_paginate">
                                                            <ul className="pagination custom-pagination pagination-simple m-0">
                                                                <li className="paginate_button page-item previous disabled" id="datable_1_previous">
                                                                    <Link to="#" className="page-link">
                                                                        <i className="ri-arrow-left-s-line" />
                                                                    </Link>
                                                                </li>
                                                                <li className="paginate_button page-item next disabled" id="datable_1_next">
                                                                    <Link to="#" className="page-link" >
                                                                        <i className="ri-arrow-right-s-line" />
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Col>
                                        </Row>

                                        <HkDataTable
                                                column={columns}
                                                rowData={data}
                                                rowSelection={true}
                                                rowsPerPage={10}
                                                searchQuery={searchTerm}
                                                classes="nowrap w-100 mb-5"
                                                responsive
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            
                            
                        </div>
                    </div>
                </SimpleBar>
            </div>

            {/* Add Tag */}
            <AddTags show={addTags} hide={() => setAddTags(!addTags)} />
            {/* Profile Information */}
            <ProfileInfo show={profileInfo} hide={() => setProfileInfo(!profileInfo)} />
            {/* More Info */}
            <MoreInfo show={moreInfo} hide={() => setMoreInfo(!moreInfo)} />
            {/* Add Bio */}
            <AddBio show={addBio} hide={() => setAddBio(!addBio)} />

            {/*Audio Call Window */}
            <AudioCallModal show={audioCall} hide={() => setAudioCall(!audioCall)} />
            {/*Video Call Window */}
            <VideoCallModal show={videoCall} hide={() => setVideoCall(!videoCall)} />

        </>
    )
}

export default EditContactBody;
