import { Edit2, Trash } from 'react-feather';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

//Images
import avatar1 from '../../assets/dist/img/logo-avatar-1.png'
import avatar2 from '../../assets/dist/img/logo-avatar-2.png'
import avatar3 from '../../assets/dist/img/logo-avatar-3.png'
import avatar4 from '../../assets/dist/img/logo-avatar-4.png'
import avatar5 from '../../assets/dist/img/logo-avatar-5.png'
import avatar6 from '../../assets/dist/img/logo-avatar-6.png'
import HkBadge from '../../components/@hk-badge/@hk-badge';
import HkProgressBar from '../../components/@hk-progressbar/HkProgressBar';

//Custom Avatar Container
const avatarFormater = (cell) => {
    return (
        cell.map((data, indx) => (
            <div className="media align-items-center" key={indx} >
                <div className="media-head me-2">
                    <div className="avatar avatar-xs avatar-rounded">
                        <img src={data.Img} alt="user" className="avatar-img" />
                    </div>
                </div>
                <div className="media-body">
                    <div className="text-high-em">{data.productName}</div>
                    <div className="fs-7"><Link to="#" className="table-link-text link-medium-em">{data.productLink}</Link></div>
                </div>
            </div>
        ))
    )
}

//Custom Ussage Container
const usageFormater = (cell) => {
    return (
        cell.map((data, indx) => (
            <div className="progress-lb-wrap" style={{ width: 251 }} key={indx} >
                <div className="d-flex align-items-center">
                    <HkProgressBar variant={data.variant} now={data.value} size="xs" rounded className="flex-1" />
                    <div className="fs-8 ms-3">{data.value}%</div>
                </div>
            </div>
        ))
    )
}

//Custom Tag Container
const tagFormater = (cell) => {
    return (
        cell.map((data, indx) => (
            <HkBadge bg="secondary" soft className="my-1  me-2" key={indx} >{data}</HkBadge>
        ))
    )
}

//Custom Action Container
const actionFormater = (cell) => {
    return (
        cell.map((data, indx) => (
            <div className="d-flex align-items-center" key={indx} >
                <Button as={Link} to={data.editLink} variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                    <span className="icon">
                        <span className="feather-icon">
                            <Edit2 />
                        </span>
                    </span>
                </Button>
                <Button as={Link} to={data.deletLink} variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete">
                    <span className="icon">
                        <span className="feather-icon">
                            <Trash />
                        </span>
                    </span>
                </Button>
            </div>
        ))
    )
}


export const columns = [
    {
        accessor: "name",
        title: "Name",
        sort: true,
        cellFormatter: avatarFormater,
    },
    {
        accessor: "usage",
        title: "Collected",
        sort: true,
        cellFormatter: usageFormater,
    },
    {
        accessor: "lastUpdate",
        title: "Last Update",
        sort: true,
    },
    {
        accessor: "tags",
        title: "Tags",
        sort: true,
        cellFormatter: tagFormater,
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: actionFormater,
    },
];

export const data = [
    {
        name: [{ Img: avatar1, productName: "Phonez", productLink: "site.com", }],
        usage: [{ value: 90, variant: "blue-dark-3" }],
        lastUpdate: "23 Nov, 2023",
        tags: ["cool", "Startup"],
        actions: [{ editLink: "#", deletLink: "#" }]
    },

    {
        name: [{ Img: avatar2, productName: "Swiggy", productLink: "site.com", }],
        usage: [{ value: 75, variant: "blue" }],
        lastUpdate: "23 Nov, 2023",
        tags: ["customer data", "admin", "+4"],
        actions: [{ editLink: "#", deletLink: "#" }]
    },

    {
        id: 3,
        name: [{ Img: avatar3, productName: "Coursera", productLink: "site.com", }],
        usage: [{ value: 50, variant: "primary" }],
        lastUpdate: "23 Nov, 2023",
        tags: ["education", "admin", "+3"],
        actions: [{ editLink: "#", deletLink: "#" }]
    },

    {
        name: [{ Img: avatar4, productName: "Tinder", productLink: "site.com", }],
        usage: [{ value: 60, variant: "primary" }],
        lastUpdate: "23 Nov, 2023",
        tags: ["Social",],
        actions: [{ editLink: "#", deletLink: "#" }]
    },

    {
        name: [{ Img: avatar5, productName: "PCD", productLink: "side.com", }],
        usage: [{ value: 30, variant: "grey" }],
        lastUpdate: "23 Nov, 2023",
        tags: ["Portal", "admin", "+3"],
        actions: [{ editLink: "#", deletLink: "#" }]
    },
    {
        name: [{ Img: avatar6, productName: "Icons 8", productLink: "site.com", }],
        usage: [{ value: 45, variant: "green-dark-1" }],
        lastUpdate: "23 Nov, 2023",
        tags: ["Library", "Asset",],
        actions: [{ editLink: "#", deletLink: "#" }]
    },


];