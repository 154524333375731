import React, { useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Eye, EyeOff } from 'react-feather';
import { Link } from 'react-router-dom';

//Image
import logoutImg from '../../../../assets/dist/img/macaroni-logged-out.png';

const Body = (props) => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.history.push("/");
    }

    return (
        <div className="hk-pg-body">
            <Container>
                <Row>
                    <Col xl={7} lg={6} className="d-lg-block d-none v-separator separator-sm">
                        <div className="auth-content py-md-0 py-8">
                            <Row>
                                <Col xxl={9} xl={8} lg={11} className="text-center mx-auto">
                                    <img src={logoutImg} className="img-fluid w-sm-40 w-50 mb-3" alt="login" />
                                    <h3 className="mb-2">Dig into Stuut team productivity.</h3>
                                    <p className="w-xxl-65 w-100 mx-auto">Invite your team and start collaborating.</p>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xl={5} lg={6} md={7} sm={10} className="position-relative mx-auto">
                        <div className="auth-content py-md-0 py-8">
                            <Form className="w-100" onSubmit={e => handleSubmit(e)} >
                                <Row>
                                    <Col lg={10} className="mx-auto">
                                        <h4 className="mb-4">Sign in to your account</h4>
                                        <Row className="gx-3">
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <div className="form-label-group">
                                                    <Form.Label>Email</Form.Label>
                                                </div>
                                                <Form.Control placeholder="Enter your email" type="text" value={userName} onChange={e => setUserName(e.target.value)} />
                                            </Col>
                                            <Col lg={12} as={Form.Group} className="mb-3">
                                                <div className="form-label-group">
                                                    <Form.Label>Password</Form.Label>
                                                    <Link to="#" className="fs-7 fw-medium">Forgot Password?</Link>
                                                </div>
                                                <InputGroup className="password-check">
                                                    <span className="input-affix-wrapper">
                                                        <Form.Control placeholder="Enter your password" value={password} onChange={e => setPassword(e.target.value)} type={showPassword ? "text" : "password"} />
                                                        <Link to="#" className="input-suffix text-muted" onClick={() => setShowPassword(!showPassword)} >
                                                            <span className="feather-icon">
                                                                {
                                                                    showPassword
                                                                        ?
                                                                        <EyeOff className="form-icon" />
                                                                        :
                                                                        <Eye className="form-icon" />
                                                                }

                                                            </span>
                                                        </Link>
                                                    </span>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-center">
                                            <Form.Check id="logged_in" className="form-check-sm mb-3" >
                                                <Form.Check.Input type="checkbox" defaultChecked />
                                                <Form.Check.Label className="text-muted fs-7">Keep me logged in</Form.Check.Label>
                                            </Form.Check>
                                        </div>
                                        <Button variant="primary" className="btn-uppercase btn-block" as={Link} to="../dashboard" >Login</Button>
                                        <p className="p-xs mt-2 text-center">New to Stuut ? <Link to="/auth/signup"><u>Create new account</u></Link></p>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Body;
