import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';
import HkBadge from '../../components/@hk-badge/@hk-badge';
import ReturningCustomerChart from './ChartData/ReturningCustomerChart';

const ReturningCustomersCard = () => {
    return (
        <Card className="card-border mb-0  h-100">
            <Card.Header className="card-header-action">
                <h6>A/R Collected</h6>
                <div className="card-action-wrap">
                    <Dropdown className="inline-block" >
                        <Dropdown.Toggle variant='transparent' className="btn-icon btn-rounded btn-flush-dark flush-soft-hover no-caret" id="dropdown-basic1">
                            <span className="icon">
                                <span className="feather-icon">
                                    <MoreVertical />
                                </span>
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                            <Dropdown.Item href="#/action-1">All Collections</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">AI Collections</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Organic Collections</Dropdown.Item>
                            <Dropdown.Divider as="div" />
                            <Dropdown.Item href="#/action-4">Refresh Data</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </Card.Header>
            <Card.Body className="text-center">
                <ReturningCustomerChart />
                <div className="d-inline-block mt-4">
                    <div className="mb-4">
                        <span className="d-block badge-status lh-1">
                            <HkBadge bg="primary" className="badge-indicator badge-indicator-nobdr d-inline-block" />
                            <span className="badge-label d-inline-block">AI Agents</span>
                        </span>
                        <span className="d-block text-dark fs-5 fw-medium mb-0 mt-1">$243k</span>
                    </div>
                    <div>
                        <span className="badge-status lh-1">
                            <HkBadge bg="primary-light-2" className="badge-indicator badge-indicator-nobdr" />
                            <span className="badge-label">Organic</span>
                        </span>
                        <span className="d-block text-dark fs-5 fw-medium mb-0 mt-1">$146k</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default ReturningCustomersCard
