import * as Icons from 'tabler-icons-react';
import HkBadge from '../../components/@hk-badge/@hk-badge';

export const SidebarMenu = [
    {
        group: '',
        contents: [
            {
                name: 'Dashboard',
                icon: <Icons.Template />,
                path: '/dashboard',
                // badge: <HkBadge size="sm" bg="pink" soft className="ms-auto" >hot</HkBadge>
            },
        ]
    },

    {
        group: 'Operations',
        contents: [

            {
                name: 'Collections',
                icon: <Icons.LayoutKanban />,
                path: '/apps/taskboard/pipeline',
                iconBadge: <HkBadge bg="primary" size="sm" pill className="position-top-end-overflow">3</HkBadge>,
            },
            
            {
                name: 'Approvals',
                icon: <Icons.ListDetails />,
                // path: '/apps/todo/task-list',
                path: '/apps/go/',
                badge: <HkBadge bg="success" soft className="ms-2">2</HkBadge>,
            },
            {
                name: 'AI Oversight',
                icon: <Icons.Inbox />,
                // path: '/apps/email',
                path: '/apps/go/',
            },
        ]
    },

    {
        group: 'Automations',
        contents: [

            {
                id: 'dash_chat',
                name: 'AI Agents',
                icon: <Icons.MessageDots />,
                path: '/apps/chat/chats',
            },

            {
                id: "dash_integ",
                name: 'Connections',
                icon: <Icons.Code />,
                path: '/apps/integrations/integration',
            },


            {
                id: "dash_task",
                name: 'Workflows',
                icon: <Icons.ListDetails />,
                // path: '/apps/todo/gantt',
                path: '/apps/go/',
            },

            
        ]
    },

    {
        group: 'Manage',
        contents: [

            {
                id: 'dash_chat',
                name: 'Invoices',
                icon: <Icons.FileDigit />,
                path: '/apps/invoices/invoice-list',
            },

            
            {
                id: "dash_partners",
                name: 'Customers',
                icon: <Icons.Notebook />,
                path: '/apps/contacts/contact-list',
            },


            {
                id: "dash_task",
                name: 'Accounts Payable',
                icon: <Icons.PigMoney />,
                path: '/apps/tomanage',
            },

            
        ]
    },

    // {
    //     group: 'Pages',
    //     contents: [
    //         {
    //             id: "dash_pages",
    //             name: 'Authentication',
    //             icon: <Icons.UserPlus />,
    //             path: '/auth',
    //             childrens: [
    //                 {
    //                     id: "dash_log",
    //                     name: 'Log In',
    //                     path: '/auth',
    //                     childrens: [
    //                         {
    //                             name: 'Login',
    //                             path: '/auth/login',
    //                         },
    //                         {
    //                             name: 'Login Simple',
    //                             path: '/auth/login-simple',
    //                         },
    //                         {
    //                             name: 'Login Classic',
    //                             path: '/auth/login-classic',
    //                         },
    //                     ]
    //                 },
    //                 {
    //                     id: "dash_sign",
    //                     name: 'Sign Up',
    //                     path: '/auth',
    //                     childrens: [
    //                         {
    //                             name: 'Signup',
    //                             path: '/auth/signup',
    //                         },
    //                         {
    //                             name: 'Signup Simple',
    //                             path: '/auth/signup-simple',
    //                         },
    //                         {
    //                             name: 'Signup Classic',
    //                             path: '/auth/signup-classic',
    //                         },
    //                     ]
    //                 },
    //                 {
    //                     name: 'Lock Screen',
    //                     path: '/auth/lock-screen',
    //                 },
    //                 {
    //                     name: 'Reset Password',
    //                     path: '/auth/reset-password',
    //                 },
    //                 {
    //                     name: 'Error 404',
    //                     path: '/error-404',
    //                 },
    //                 {
    //                     name: 'Error 503',
    //                     path: '/auth/error-503',
    //                 },
    //             ]
    //         },
    //         {
    //             id: "dash_profile",
    //             name: 'Profile',
    //             icon: <Icons.UserSearch />,
    //             path: '/pages',
    //             badgeIndicator: <HkBadge bg="danger" indicator className="position-absolute top-0 start-100" />,
    //             childrens: [
    //                 {
    //                     name: 'Profile',
    //                     path: '/pages/profile',
    //                     grp_name: "apps",
    //                 },
    //                 {
    //                     name: 'Edit Profile',
    //                     path: '/pages/edit-profile',
    //                     grp_name: "apps",
    //                 },
    //                 {
    //                     name: 'Account',
    //                     path: '/pages/account',
    //                     grp_name: "apps",
    //                 },
    //             ]
    //         },

    //     ]
    // },

    {
        group: 'Help Center',
        contents: [
            {
                name: 'Guides',
                icon: <Icons.FileCode2 />,
                path: '#',
            },
            {
                name: 'FAQs',
                icon: <Icons.Help />,
                path: '#',
            },
            {
                name: 'Support',
                icon: <Icons.Ticket />,
                path: '#',
            },
        ]
    },

]