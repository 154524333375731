import React from 'react';
import { Archive, Copy, Edit, MoreVertical, Trash, Trash2 } from 'react-feather';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import HkBadge from '../../../components/@hk-badge/@hk-badge';

// Images
import avatar1 from '../../../assets/dist/img/avatar1.jpg';
import avatar2 from '../../../assets/dist/img/avatar2.jpg';
import avatar3 from '../../../assets/dist/img/avatar3.jpg';
import avatar7 from '../../../assets/dist/img/avatar7.jpg';
import avatar8 from '../../../assets/dist/img/avatar8.jpg';
import avatar9 from '../../../assets/dist/img/avatar9.jpg';
import avatar10 from '../../../assets/dist/img/avatar10.jpg';
import avatar13 from '../../../assets/dist/img/avatar13.jpg';

// Custom Avatar Container
const nameFormater = (cell) => {
    if (!cell || !Array.isArray(cell)) return null;
    return cell.map((data, indx) => (
        <div className="media align-items-center" key={indx}>
            <div className="media-head me-2">
                <div className={classNames("avatar avatar-xs avatar-rounded", data.cstmAvt ? `avatar-soft-${data.avtBg}` : "")}>
                    {data.Img && <img src={data.Img} alt="user" className="avatar-img" />}
                    {data.cstmAvt && <span className="initial-wrap">{data.cstmAvt}</span>}
                </div>
            </div>
            <div className="media-body">
                <span className="d-block text-high-em">{data.userName}</span>
            </div>
        </div>
    ));
};

// Custom Tag Container
const tagFormater = (cell) => {
    if (!cell || !Array.isArray(cell)) return null;
    return cell.map((data, indx) => (
        <HkBadge bg={data.bg} soft className="my-1 me-2" key={indx}>{data.title}</HkBadge>
    ));
};

// Custom Action Container
const actionFormater = (cell) => {
    if (!cell || !Array.isArray(cell)) return null;
    return cell.map((data, indx) => (
        <div className="d-flex align-items-center" key={indx}>
            <div className="d-flex">
                <Button variant="flush-dark" as={Link} to={data.archiveLink} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Archive">
                    <Archive />
                </Button>
                <Button variant="flush-dark" as={Link} to={data.editLink} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                    <Edit />
                </Button>
                <Button variant="flush-dark" as={Link} to={data.deleteLink} className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete">
                    <Trash />
                </Button>
            </div>
            <Dropdown>
                <Dropdown.Toggle variant="flush-dark" className="btn-icon btn-rounded flush-soft-hover no-caret" aria-expanded="false" data-bs-toggle="dropdown">
                    <MoreVertical />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                    <Dropdown.Item as={Link} to={data.editLink}>
                        <Edit /> Edit Contact
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="#">
                        <Trash2 /> Delete
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to="#">
                        <Copy /> Duplicate
                    </Dropdown.Item>
                    <Dropdown.Divider as="div" />
                    <Dropdown.Header className="dropdown-header-bold">Change Labels</Dropdown.Header>
                    <Dropdown.Item as={Link} to="#">Design</Dropdown.Item>
                    <Dropdown.Item as={Link} to="#">Developer</Dropdown.Item>
                    <Dropdown.Item as={Link} to="#">Inventory</Dropdown.Item>
                    <Dropdown.Item as={Link} to="#">Human Resource</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    ));
};

export const columns = [
    {
        accessor: "name",
        title: "Customer",
        sort: true,
        cellFormatter: nameFormater,
    },
    {
        accessor: "email",
        title: "Amount Owed",
        sort: true,
        cellFormatter: (cell) => <span className="text-truncate mw-150p d-block">{cell}</span>,
    },
    {
        accessor: "phone",
        title: "# Invoices",
        sort: true,
    },
    {
        accessor: "#past45",
        title: "# Past 45",
        sort: true,
    },
    {
        accessor: "$past45",
        title: "$ Past 45",
        sort: true,
    },
    {
        accessor: "tags",
        title: "Health",
        sort: true,
        cellFormatter: tagFormater,
    },
    {
        accessor: "owner",
        title: "Owner",
        sort: true,
        cellFormatter: nameFormater, // Reusing nameFormater for Owner column
    },
    {
        accessor: "dateCreated",
        title: "Contacted",
        sort: true,
    },
    {
        accessor: "actions",
        title: "",
        cellFormatter: actionFormater,
    },
];

export const data = [
    {
        starred: true,
        name: [{ Img: avatar1, userName: "SolarFlare" }],
        email: "$20,120.23",
        phone: "4",
        "#past45": "2",
        "$past45": "$10,050.60",
        tags: [{ title: "2/5", bg: "danger" }],
        owner: [{ Img: avatar1, userName: "Devin Jameson" }],
        dateCreated: "21 Nov, 2023",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },
    {
        starred: true,
        name: [{ Img: avatar1, userName: "Acme Corporation" }],
        email: "$12,340.23",
        phone: "6",
        "#past45": "1",
        "$past45": "$5,031.43",
        tags: [{ title: "4/5", bg: "success" }],
        owner: [{ Img: avatar1, userName: "Sylvia Moscowitz" }],
        dateCreated: "17 Dec, 2023",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },{
        starred: true,
        name: [{ Img: avatar1, userName: "Gibson Bank" }],
        email: "$15,234.49",
        phone: "13",
        "#past45": "4",
        "$past45": "$5,568.43",
        tags: [{ title: "3/5", bg: "warning" }],
        owner: [{ Img: avatar1, userName: "Michael Sawyer" }],
        dateCreated: "3 Jan, 2024",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },
    {
        starred: true,
        name: [{ Img: avatar1, userName: "SolarFlare" }],
        email: "$20,120.23",
        phone: "4",
        "#past45": "2",
        "$past45": "$10,050.60",
        tags: [{ title: "2/5", bg: "danger" }],
        owner: [{ Img: avatar1, userName: "Devin Jameson" }],
        dateCreated: "21 Nov, 2023",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },
    {
        starred: true,
        name: [{ Img: avatar1, userName: "Acme Corporation" }],
        email: "$12,340.23",
        phone: "6",
        "#past45": "1",
        "$past45": "$5,031.43",
        tags: [{ title: "4/5", bg: "success" }],
        owner: [{ Img: avatar1, userName: "Sylvia Moscowitz" }],
        dateCreated: "17 Dec, 2023",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },{
        starred: true,
        name: [{ Img: avatar1, userName: "Gibson Bank" }],
        email: "$15,234.49",
        phone: "13",
        "#past45": "4",
        "$past45": "$5,568.43",
        tags: [{ title: "3/5", bg: "warning" }],
        owner: [{ Img: avatar1, userName: "Michael Sawyer" }],
        dateCreated: "3 Jan, 2024",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },
    
    {
        starred: true,
        name: [{ Img: avatar1, userName: "SolarFlare" }],
        email: "$20,120.23",
        phone: "4",
        "#past45": "2",
        "$past45": "$10,050.60",
        tags: [{ title: "2/5", bg: "danger" }],
        owner: [{ Img: avatar1, userName: "Devin Jameson" }],
        dateCreated: "21 Nov, 2023",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },
    {
        starred: true,
        name: [{ Img: avatar1, userName: "Acme Corporation" }],
        email: "$12,340.23",
        phone: "6",
        "#past45": "1",
        "$past45": "$5,031.43",
        tags: [{ title: "4/5", bg: "success" }],
        owner: [{ Img: avatar1, userName: "Sylvia Moscowitz" }],
        dateCreated: "17 Dec, 2023",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },{
        starred: true,
        name: [{ Img: avatar1, userName: "Gibson Bank" }],
        email: "$15,234.49",
        phone: "13",
        "#past45": "4",
        "$past45": "$5,568.43",
        tags: [{ title: "3/5", bg: "warning" }],
        owner: [{ Img: avatar1, userName: "Michael Sawyer" }],
        dateCreated: "3 Jan, 2024",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },
    
    {
        starred: true,
        name: [{ Img: avatar1, userName: "SolarFlare" }],
        email: "$20,120.23",
        phone: "4",
        "#past45": "2",
        "$past45": "$10,050.60",
        tags: [{ title: "2/5", bg: "danger" }],
        owner: [{ Img: avatar1, userName: "Devin Jameson" }],
        dateCreated: "21 Nov, 2023",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },
    {
        starred: true,
        name: [{ Img: avatar1, userName: "Acme Corporation" }],
        email: "$12,340.23",
        phone: "6",
        "#past45": "1",
        "$past45": "$5,031.43",
        tags: [{ title: "4/5", bg: "success" }],
        owner: [{ Img: avatar1, userName: "Sylvia Moscowitz" }],
        dateCreated: "17 Dec, 2023",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },{
        starred: true,
        name: [{ Img: avatar1, userName: "Gibson Bank" }],
        email: "$15,234.49",
        phone: "13",
        "#past45": "4",
        "$past45": "$5,568.43",
        tags: [{ title: "3/5", bg: "warning" }],
        owner: [{ Img: avatar1, userName: "Michael Sawyer" }],
        dateCreated: "3 Jan, 2024",
        actions: [{ archiveLink: "#", editLink: "edit-contact", deleteLink: "#" }]
    },
    
    
    // ... add other data entries here
];

